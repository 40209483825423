import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "delete" }
const _hoisted_2 = { class: "delete__wrap" }
const _hoisted_3 = { class: "delete__my-phone" }
const _hoisted_4 = { class: "timer" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "delete__buttons" }
const _hoisted_7 = {
  key: 0,
  class: "error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, " We sent you code on this email " + _toDisplayString($data.email), 1),
      _cache[5] || (_cache[5] = _createStaticVNode("<div class=\"delete__title\" data-v-34583b8f>Are you sure?</div><div class=\"delete__section-title\" data-v-34583b8f> This action <span data-v-34583b8f>CANNOT be undone.</span> Please be certain about your decision. </div><div class=\"delete__checkbox-group\" data-v-34583b8f><label for=\"first\" class=\"custom-checkbox\" data-v-34583b8f><input id=\"first\" type=\"checkbox\" checked disabled data-v-34583b8f><span data-v-34583b8f>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam</span></label><label for=\"second\" class=\"custom-checkbox\" data-v-34583b8f><input id=\"second\" type=\"checkbox\" checked disabled data-v-34583b8f><span data-v-34583b8f>Nisi ut aliquid ex ea commodi consequatur quis nostrum ex</span></label><label for=\"three\" class=\"custom-checkbox\" data-v-34583b8f><input id=\"three\" type=\"checkbox\" checked disabled data-v-34583b8f><span data-v-34583b8f>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam</span></label><label for=\"fourth\" class=\"custom-checkbox\" data-v-34583b8f><input id=\"fourth\" type=\"checkbox\" checked disabled data-v-34583b8f><span data-v-34583b8f>Nisi ut aliquid ex ea commodi consequatur quis nostrum ex</span></label><label for=\"fourth\" class=\"custom-checkbox\" data-v-34583b8f><input id=\"fourth\" type=\"checkbox\" checked disabled data-v-34583b8f><span data-v-34583b8f>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam</span></label></div><div class=\"delete__text-input\" data-v-34583b8f>Enter code from email</div>", 4)),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        id: "code",
        placeholder: "Input code",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.code) = $event)),
        class: "delete__input"
      }, null, 512), [
        [_vModelText, $data.code]
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[4] || (_cache[4] = _createTextVNode(" Remaining time: ")),
        (!$data.resendCode)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString($data.remainingTime) + " s", 1))
          : (_openBlock(), _createElementBlock("span", {
              key: 1,
              onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.sendCode()))
            }, "Resend"))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          class: "delete__cancel-button",
          onClick: _cache[2] || (_cache[2] = ($event: any) => ($options.goToBack()))
        }, "Back"),
        _createElementVNode("div", {
          class: "delete__button",
          onClick: _cache[3] || (_cache[3] = ($event: any) => ($options.deleteAccount()))
        }, "Confirm")
      ]),
      ($data.errorOtpData)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString($data.errorOtpData.otp[0]), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}