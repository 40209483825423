
import Header from '@/components/sections/Header.vue'
import axios from 'axios'
export default {
  name: 'Delete',
  layout: 'delete',
  scrollToTop: true,
  data () {
    return {
      code: '',
      email: '',
      remainingTime: 60,
      userEmail: '',
      resendCode: false,
      errorData: {},
      errorOtpData: null
    }
  },
  components: {
    Header
  },
  created () {
    this.startTimer()
  },
  mounted () {
    this.email = localStorage.getItem('sraEmail')
  },
  methods: {
    setValue (data, item) {
      this[item] = data
    },
    startTimer () {
      this.timerInterval = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime--
        } else {
          clearInterval(this.timerInterval)
          this.resendCode = true
        }
      }, 1000)
    },
    sendCode () {
      this.resendCode = false
      this.remainingTime = 60
      this.startTimer()
      this.sendEmail()
    },
    async sendEmail () {
      this.$store.dispatch('deleteAccount/sendOtp', this.email).then(
        (res) => {
          localStorage.setItem('sraEmail', this.email)
          this.$router.push('/delete-second-step')
        },
        (error) => {
          this.errorData = error.response.data.errors
        }
      )
    },
    async deleteAccount () {
      this.$store.dispatch('deleteAccount/deleteStudent', this.code).then(
        (res) => {
          this.$router.push('/delete-finish')
          localStorage.removeItem('sraEmail')
        },
        (error) => {
          this.errorOtpData = error.response.data.errors
        }
      )
    },
    goToBack () {
      this.$router.push('/delete')
    }
  }
}
